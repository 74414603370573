import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ReactComponent as CaretRightIcon } from 'assets/icons/caret-right.svg';
import { ReactComponent as DoubleCaretDownIcon } from 'assets/icons/double-caret-down.svg';
import { ReactComponent as HeroCurve } from 'assets/icons/hero-curve.svg';
import cn from 'classnames';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Button, ContactBlock, Footer, MapBlock } from 'components';
import { useScrollPosition, useWindowSize } from 'hooks';
import { orderBy } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { useContentful } from 'react-contentful';
import Helmet from 'react-helmet';
import { Carousel } from 'react-responsive-carousel';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'utils';
import { ServiceType } from '../Service';
import classes from './styles.module.scss';

export type HomePropType = {
  className?: string;
};

type HomepageDataType = {
  fields: {
    heroTitle: string;
    heroDescription: string;
    browserTabLabel: string;
    searchEngineDescription: string;
    contrastTopSection: any;
    heroVideo: {
      fields: {
        file: {
          url: string;
        };
      };
    };
  };
};

const Home: React.FC<HomePropType> = ({ className }) => {
  const [hideArrow, setHideArrow] = useState<boolean>(false);
  const [discountAttract, setDiscountAttract] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const { data: serviceResult } = useContentful({
    contentType: 'service',
  });
  /*eslint-disable */
  const services = useMemo(
    () =>
      orderBy(((serviceResult as any)?.items || []) as ServiceType[], ['fields.order'], ['asc']),
    [serviceResult]
  );

  const handleScroll = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const { data: homepageResult } = useContentful({
    contentType: 'homepage',
  });

  /*eslint-disable */
  const homepageData = useMemo(
    () => ((homepageResult as any)?.items || [])[0] as HomepageDataType,
    [homepageResult]
  );

  const { data: testimonialsResult } = useContentful({
    contentType: 'testimonial',
  });

  /*eslint-disable */
  const testimonials = useMemo(
    () =>
      ((testimonialsResult as any)?.items || []) as {
        fields: { content: any; sourceName: string };
      }[],
    [testimonialsResult]
  );

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -20) {
      setHideArrow(true);
      setDiscountAttract(true);
    } else {
      setHideArrow(false);
    }
  });

  const redirect = (url: string) => {
    navigate(url);
  };

  const contrastContentHtml = useMemo(
    () => documentToHtmlString(homepageData?.fields?.contrastTopSection),
    [homepageData?.fields?.contrastTopSection]
  );

  return (
    <div className={cn(classes.base, className)}>
      <Helmet>
        <title>{homepageData?.fields?.browserTabLabel}</title>
        <meta name="description" content={homepageData?.fields?.searchEngineDescription} />
        <link rel="canonical" href="https://elit.care" />
      </Helmet>
      <div id="hero" className={classes.hero}>
        <video
          src={`https:${homepageData?.fields?.heroVideo?.fields?.file?.url}`}
          autoPlay
          muted
          playsInline
          loop
        />
        <div className={classes.overlay}>
          <h1>{homepageData?.fields?.heroTitle}</h1>
          <p>{homepageData?.fields?.heroDescription}</p>
          <Button color="outline" className={classes.button} onClick={handleScroll} size="big">
            Learn More
          </Button>
          <DoubleCaretDownIcon
            className={cn(classes.scrollIcon, { [classes['scrollIcon--hidden']]: hideArrow })}
            onClick={handleScroll}
          />
          <HeroCurve className={classes.curve} />
        </div>
      </div>
      <div className={classes.body}>
        <div ref={contentRef} className={classes.content}>
          <div id="about-us" className={classes['content-top']}>
            <div className={classes.inner}>
              <p dangerouslySetInnerHTML={{ __html: contrastContentHtml }}></p>
              <div className={classes.actionsContainer}>
                <Link to={routes.ABOUT_US}>
                  Learn More <CaretRightIcon />
                </Link>
              </div>
            </div>
          </div>

          <div id="services" className={classes['content-main']}>
            <h2>Services</h2>
            <p>
              We provide a variety of services to help you be at your best.{' '}
              <a
                href="https://sites.google.com/view/elitcareproviders"
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: '1em', fontWeight: 'bold' }}
              >
                Direct Billing - Click to View Eligible Providers
              </a>
            </p>

            <div className={classes.serviceBoxContainer}>
              {(services || []).map((service) => {
                return (
                  <div
                    className={classes.serviceTile}
                    onClick={() => redirect(service.fields.slug)}
                  >
                    <img
                      src={`https:${service.fields.icon?.fields?.file?.url}`}
                      alt={`${service.fields.name} icon`}
                    />
                    <div className={classes.line} />
                    <h3>{service.fields.name}</h3>
                    <p>{service.fields.shortDescription}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <div id="reviews" className={classes['content-review']}>
            <div className={classes.inner}>
              <h2>What our patients say about us</h2>
              <Carousel
                className={classes.carousel}
                showIndicators={false}
                showArrows={false}
                infiniteLoop
                showThumbs={false}
                autoPlay
                stopOnHover
                swipeable
                emulateTouch
                showStatus={false}
                transitionTime={1000}
                interval={6000}
              >
                {(testimonials || []).map((testimonial) => {
                  return (
                    <div key={testimonial.fields?.sourceName}>
                      <div className={classes.slide}>
                        {/* <img src={avatarExample} /> */}
                        <div className={classes.content}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: documentToHtmlString(testimonial.fields?.content),
                            }}
                          />
                          <p className={classes.name}>{testimonial.fields?.sourceName}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <MapBlock />
            <ContactBlock />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
