import { Wrapper as GoogleMapWrapper } from '@googlemaps/react-wrapper';
import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import GoogleMapReact from 'google-map-react';
import React, { FC } from 'react';
import { useContentful } from 'react-contentful';
import { isTrackingAllowed } from 'utils/cookieConsent';
import classes from './styles.module.scss';

export type ContactBlockPropType = {
  className?: string;
};

const AnyReactComponent: FC<{ lat: number; lng: number; text: string }> = ({ text }) => (
  <div className={classes.mapMarker}>
    <MapPinIcon />
    {text}
  </div>
);

const MapBlock: React.FC<ContactBlockPropType> = ({ className }) => {
  const { data } = useContentful({
    contentType: 'contactInformation',
  });
  /*eslint-disable */
  const contactInfo = (data as any)?.items?.[0] || {};
  const defaultProps = {
    center: {
      lat: contactInfo?.fields?.latitude || 0,
      lng: contactInfo?.fields?.longitude || 0,
    },
    zoom: 15,
  };

  if (!isTrackingAllowed()) return null;

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapWrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY || '' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent lat={defaultProps.center.lat} lng={defaultProps.center.lng} text="" />
        </GoogleMapReact>
      </GoogleMapWrapper>
    </div>
  );
};

export default MapBlock;
