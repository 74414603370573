import React from 'react';
import cn from 'classnames';
import { Layout } from 'components';
import classes from './styles.module.scss';
// import data from 'data';

const Physio: React.FC = () => {
  return (
    <Layout title="Terms of Service" className={cn(classes.body)}>
      <div className={classes.content}>
        <p>
          <strong>Terms of Use</strong>
        </p>
        <p>
          <strong>Date of Last Amendment: January 30, 2023</strong>
        </p>
        <p>Thank you for visiting https://elit.care.</p>
        <p>
          The https://elit.care website (&ldquo;Website&rdquo;) is owned and operated by 1000251423
          Ontario Inc. c.o.b. Elit Care (&ldquo;Elit Care&rdquo; &ldquo;the Clinic,&rdquo;
          &ldquo;we,&rdquo; &ldquo;us,&rdquo; &ldquo;our&rdquo;), located at 412 Front Street East,
          Toronto, ON M5A 0H8. As applicable, Elit Care includes our shareholders, directors,
          officers, employees, contractors, subcontractors, freelancers, agents, representatives,
          successors, assigns, and affiliated and subsidiary companies.
        </p>
        <p>
          The Website provides Patients and Users (as defined in these Terms of Use) with
          information about Elit Care and the services we provide. It also provides Patients with a
          convenient portal to book appointments. These Terms outline (A) the terms and conditions
          of Patients&rsquo; use of the Clinic, and (B){' '}
          <a
            href="https://docs.google.com/document/d/1Tuve65cy11AFt4gePL8GXrraNs2crQUz/edit#heading=h.30j0zll"
            target="_blank"
            rel="noreferrer"
          >
            the terms of use of this Website
          </a>
          .
        </p>
        <p>
          By visiting our Website or the Clinic, Patients and Users agree to these Terms of Use
          (&ldquo;Terms&rdquo;). Please review them carefully. These Terms are a binding legal
          contract between Patients/Users and us. If a Patient or User cannot agree to these Terms,
          they are asked not to use our Website or visit the Clinic. Patients or Users with
          questions about these Terms may contact us at{' '}
          <a href="http://admin@elit.care" target="_blank" rel="noreferrer">
            admin@elit.care
          </a>
          . Please indicate &ldquo;Terms of Use&rdquo; in the subject line.
        </p>
        <p>
          Please review our Privacy Policy. A Patient or User who does not consent to our collection
          and use of personal information as explained in our Privacy Policy is asked to follow the
          procedures in that Policy.
        </p>
        <p>
          We may amend these Terms without notice. Check them from time to time. If the Date of Last
          Amendment is more recent than your last visit, please review them again. Continued to the
          Website or Clinic after an amendment means acceptance of the new Terms.
        </p>

        <h1>A. Use of Our Services</h1>

        <p>
          <em>i. Introduction and Definitions</em>
        </p>
        <p>
          Elit Care provides therapeutic (physiotherapy, massage therapy, chiropractic) care at its
          Clinic. We focus on rehabilitation, prevention and decreased dependency. Our team of
          therapists works together to provide the best possible care for our Patients and to
          empower them to take an active role in their own health and wellness.
        </p>
        <p>&ldquo;Patients&rdquo; means individuals who receive therapeutic care at our Clinic.</p>
        <p>
          &ldquo;Users&rdquo; means visitors to the Website. A Patient is also a User when browsing
          or making a booking through the Website.
        </p>
        <p>
          <em>
            ii. Who Can Use Our Services and Booking, Cancelling, and Rescheduling Appointments
          </em>
        </p>
        <p>
          All persons, adults and minors (please see our Privacy Policy for information about our
          provision of care to minors), are welcome at the Clinic. Please make an appointment using
          the booking portal on the Website (preferred) or by telephone.
        </p>
        <p>
          If you telephone and have to leave a message, please provide your name, telephone number,
          the name of your therapist, and the date and time you prefer for your appointment. If you
          leave a message, please note that your appointment is not confirmed until we email you
          back. If you do not have access to email, we will return your call. If you do not answer,
          we will leave a message if we can. Please check your messages if you are waiting for a
          callback or try us again. If we cannot leave a message, please ensure you call back and
          speak with a member of our staff.
        </p>
        <p>
          Please do not come to the Clinic until your appointment is confirmed. Appointments booked
          through the Website portal are confirmed at the time of booking, and further action on
          your part is not required.
        </p>
        <p>
          Please remember that booking an appointment on our Website has the same force and effect
          as making an appointment by telephone or face-to-face with Clinic staff.
        </p>
        <p>
          You may cancel or reschedule appointments through the Website booking portal and this is
          your best option. In the alternative, you may telephone or email (
          <a href="http://admin@elit.care" target="_blank" rel="noreferrer">
            admin@elit.care
          </a>
          ) the Clinic. If emailing, please indicate &ldquo;Cancellation&rdquo; or
          &ldquo;Rescheduling&rdquo; in the subject line.
        </p>
        <p>Late cancellation/rescheduling fees may apply.</p>
        <p>
          <em>iii. Conduct</em>
        </p>
        <p>
          Patients and visitors are reminded that appropriate behaviour (courtesy, mindfulness,
          respect) ensures that the Clinic&rsquo;s calm and nurturing environment supports the
          health and wellness of all.
        </p>
        <p>
          Please refrain from behaviour that is disruptive, threatening, violent, or otherwise
          inappropriate. Disruptive behaviour includes, for example, yelling, using profanity,
          making threatening gestures, verbal harangues, and refusing reasonable requests.
        </p>
        <p>
          Obviously, intentionally damaging the Clinic&rsquo;s equipment or property or attempting
          to intimidate or harass therapists, staff members, or other Patients or visitors with
          harassing, offensive, derogatory, discriminatory, or intimidating remarks is unacceptable.
        </p>
        <p>
          The Clinic reserves the right to remove anyone engaging in inappropriate or unacceptable
          conduct from its premises and to ban those individuals from the Clinic permanently or
          temporarily.
        </p>
        <p>
          <em>iv. Fees and Payment</em>
        </p>
        <p>
          The fees for our services are posted on our Website and at the Clinic. H.S.T. is payable
          for some services.
        </p>
        <p>
          Payments can only be made to the Clinic (at Reception) and not to your therapist.
          THERAPISTS ARE NOT AUTHORIZED TO ACCEPT PAYMENT FOR SERVICES and Patients remain
          responsible for fees notwithstanding any payments made to a therapist.
        </p>
        <p>Fees are paid before services are rendered.</p>
        <p>
          Payment for any product offered for sale at the Clinic, whether by the Clinic or a
          therapist, must be made to the Clinic (at Reception) at the time of purchase. THERAPISTS
          ARE NOT AUTHORIZED TO ACCEPT PAYMENT FOR PRODUCTS, even products they source and offer to
          Patients.
        </p>
        <p>
          Patients removing from the Clinic products they have not paid the Clinic for are guilty of
          theft, even if they paid their therapist.
        </p>
        <p>
          <em>v. Waiver of Liability</em>
        </p>
        <p>
          Elit Care therapists have been selected for their competence (skills, knowledge, and
          experience) and their reputations for practicing to the highest professional standard in
          their field. We are proud that they have elected to offer their services at our
          Clinic.&nbsp;
        </p>
        <p>
          All Elit Care therapists are members in member in good standing of their professional
          regulatory organizations, e.g., the College of Physiotherapists of Ontario, the College of
          Massage Therapists of Ontario, and the College of Chiropractors of Ontario.
        </p>
        <p>
          Elit Care therapists all carry (paid-up, in-force) professional liability insurance
          coverage that meets, at a minimum, the requirements of their professional regulatory
          organization.
        </p>
        <p>
          We are confident that the care you receive from your Elit Care therapist will be
          beneficial and not harmful to your health or well-being. However, the provision of any
          therapeutic care always carries with it foreseeable and unforeseeable risks, however
          small.
        </p>
        <p>
          Elit Care recommends that Patients consult their physician before obtaining therapeutic
          care at our (or any) Clinic.
        </p>
        <p>
          Before receiving care at our Clinic, Patients (or their legal representatives) will be
          required to read, understand and sign a Waiver and Release of Liability in favour of the
          Clinic. This means Patients acknowledge and understand that the Clinic is not liable for
          personal injuries of any kind, or any other damages or losses that may occur as a result
          of the receipt of therapeutic care at our Clinic. (The Waiver and Release of Liability
          does not release your therapist from legal liability. As noted, Elit Care therapists carry
          insurance for such matters.)
        </p>
        <p>
          <em>vi. No Warranty</em>
        </p>
        <p>
          To the maximum extent allowed by law, Elit Care and its therapists offer no warranties or
          undertakings of any kind, express or implied, with respect to therapeutic care provided.
          Different Patients respond to treatment differently. Use of and reliance on our services
          are at each Patient&rsquo;s own risk. No advice or information obtained from Elit Care or
          a therapist directly or through the Website creates any warranty not expressly stated in
          these Terms. Without limiting the foregoing, we do not warrant that any information
          provided on or by way of the Website is accurate, reliable, or correct. Nor do we warrant
          that our services will meet Patients&rsquo; requirements.
        </p>
        <p>
          <em>vii. Indemnification</em>
        </p>
        <p>
          Except as prohibited by law, Patients agree to indemnify and hold harmless Elit Care, its
          directors, officers, employees, contractors, subcontractors, Website content contributors,
          agents, co-branders, suppliers, subsidiaries, parent companies, and affiliates from any
          and all liabilities, losses, claims (including, but not limited to, claims for injunctive
          relief), demands, disputes, damages, losses, liens, causes of action, suits, civil,
          criminal, statutory, or administrative actions or proceedings, fines, taxes, assessments,
          penalties, judgments, and/or other expenses of any kind, nature, or description whatsoever
          (including, but not limited to, Elit Care&rsquo;s legal fees and expenses and costs of
          investigation) resulting from or in any way connected with (1) a Patient&rsquo;s
          attendance at our premises and receipt of our services, (2) a Patient&rsquo;s use of
          information provided on the Website or by a therapist or Clinic employee, (3) privacy,
          tort, or other claims relating to personal health information provided to Elit Care
          whether or not provided by the Patient, and/or (4) a Patient&rsquo;s breach of these
          Terms. Patients warrant that, if applicable, they have the authority to bind their
          organization/employer/etc. in connection with this and other Indemnifications and
          limitations of Warranty in these Terms.
        </p>
        <p>
          <em>viii. Our additional remedies</em>
        </p>
        <p>
          In order to avoid irreparable injury to Elit Care, in the event of a Patient&rsquo;s
          breach or threatened breach of these Terms, we may seek an injunction and/or other
          equitable relief restraining such breach or threatened breach in a court of law. Nothing
          in these Terms will be interpreted as prohibiting us from pursuing any other remedies
          available for such breach or threatened breach, including the recovery of monetary damages
          from a Patient.
        </p>
        <p>
          <em>
            ix. As applicable in sections vi, vii, and viii of part A of these Terms,
            &ldquo;Patient&rdquo; includes &ldquo;User.&rdquo;
          </em>
        </p>
        <p>
          <em>x. Other Terms and Conditions of Use of our Services</em>
        </p>
        <p>
          At our discretion but subject to the Personal Health Information Protection Act (see
          our&nbsp; Privacy Policy), Elit Care may assign, transfer, or subcontract any of our
          rights or obligations under these Terms to any third party. Any representations,
          warranties, and indemnification obligations made or undertaken by a Patient will survive
          the termination of the Patient&rsquo;s relationship with us.
        </p>
        <p>
          Our delay in exercising any right or remedy under these Terms will never operate as a
          waiver of that right or remedy and will not affect our ability to exercise that right or
          remedy subsequently. We must agree to any waiver in writing.
        </p>
        <p>
          These Terms supersede any other terms previously published by us and any other
          representations or statements made by us to a User, whether oral, written, or otherwise.
        </p>
        <p>
          Patients and Users each verify that any contact or other information provided to us in
          connection with the use of our services is true and accurate.
        </p>
        <p>
          <em>xi. Dispute Resolution</em>
        </p>
        <p>
          These Terms of Service and any agreement of purchase and sale between Patients/Users and
          Elit Care will be construed and governed by the laws of the province of Ontario, Canada.
          Any matters in dispute between a Patient and us will be submitted to and determined by a
          court of competent jurisdiction in the province of Ontario.
        </p>

        <h1>B. Use of the Website</h1>

        <p>
          <em>i. Users Rights</em>
        </p>
        <p>
          Users (which, as applicable, includes Patients in this part B) are free to browse the
          Website without cost or obligation.
        </p>
        <p>
          <em>ii. Bookings</em>
        </p>
        <p>
          We prefer that appointments are booked through our Website portal, although this is not
          required. Users/Patients are reminded that an appointment made through the Website has the
          same force and effect as an appointment made by telephone or face-to-face with a Clinic
          staff member.
        </p>
        <p>
          Users may cancel or reschedule appointments through the Website portal (preferred). In the
          absence of Internet access, appointments may be cancelled or rescheduled by telephone.
        </p>
        <p>Late cancellation/rescheduling fees may apply.</p>
        <p>
          <em>iii. Other User Obligations</em>
        </p>
        <p>Users will only use our Website for lawful purposes.</p>
        <p>Without our prior written consent, Users may not</p>
        <ul>
          <li>copy, reproduce, use, or sell content found on the Website.</li>
          <li>modify, distribute, or re-post any content on the Website for any purpose.</li>
          <li>
            use the content of the Website for any commercial purpose for which it is not intended.
          </li>
        </ul>
        <p>Users agree that they will</p>
        <ul>
          <li>
            not disrupt or interfere with the security of, or otherwise abuse, the Website or any
            services, system resources, accounts, servers, or networks connected to or accessible
            through the Website or an affiliated or linked website.
          </li>
          <li>
            not disrupt or interfere with any other User&apos;s use or enjoyment of the Website or
            an affiliated or linked website.
          </li>
          <li>
            not transmit through or on the Website any viruses or other harmful, disruptive, or
            destructive files.
          </li>
          <li>
            not use, frame, or utilize framing techniques to enclose Elit Care&rsquo;s trademark,
            logo, or other proprietary information (including images/text found on our Website, or
            the layout/design of any page or form on our Website) without our prior written consent.
          </li>
          <li>
            not reverse engineer or create derivative works based on our Website or any content
            available through our Website including, without limitation, any software.
          </li>
          <li>
            not use meta tags or any other &quot;hidden text&quot; utilizing Elit Care&rsquo;s name,
            trademark, logo, or other proprietary information without our prior written consent.
          </li>
          <li>
            not obtain or attempt to obtain unauthorized access to our Website or areas of the
            Website that are restricted from general access.
          </li>
        </ul>
        <p>
          <em>iv. Disclaimer of all Warranties with respect to the Website</em>
        </p>
        <p>
          Our Website is provided on an &ldquo;as-is&rdquo; and &ldquo;as available&rdquo; basis.
          Except as provided in these Terms and to the fullest extent permitted by applicable law,
          we expressly disclaim all warranties, express or implied, with respect to our Website or
          use thereof including without limitation, any warranties of merchantability, fitness for a
          particular purpose, or non-infringement.
        </p>
        <p>
          We do not warrant that our Website will meet Users&apos; requirements or that access to it
          will be uninterrupted, timely, secure, error-free, or that defects, if any, will be
          corrected. Except as expressly provided in these Terms, we make no warranties with respect
          to the accuracy, quality, or reliability of the information on our Website.
        </p>
        <p>
          Users agree that any material and/or data downloaded or otherwise obtained from our
          Website is used at their own risk and that they will be solely responsible for any damage
          to their computer or device, or for loss of data that results from the download.
        </p>
        <p>
          We disclaim any responsibility for any product or service advertised or offered by a third
          party through our Website or any linked website or service. We are not a party to and do
          not in any way monitor any transaction between a User and a third party.
        </p>
        <p>
          <em>
            v. Protection of our Intellectual Property rights and Users&rsquo; license to Use the
            Website
          </em>
        </p>
        <p>
          Users agree that the content on our Website including text, graphics, software, sound
          (including spoken words and music), photographs, video clips, and content provided by
          sponsors, advertisers, or other Users (&quot;Intellectual Property&quot;) is owned by Elit
          Care or the applicable third party and is protected by copyright, trademark, patent, or
          other proprietary rights and laws.
        </p>
        <p>
          Users may not use or display any of our trademarks, logos, or service marks without our
          prior written consent. Users may not use or display any other trademarks, logos, or
          service marks displayed on our Website without the permission of the owners.
        </p>
        <p>
          Users are granted a non-exclusive, non-transferable, revocable, limited license to view
          content on our Website for the purpose of obtaining information about and purchasing our
          service. Users who purchase our service are granted a non-exclusive, non-transferable,
          revocable, limited license to access non-public areas of our Website in order to use our
          service including the dashboard.
        </p>
        <p>
          Except as expressly provided in these Terms, nothing on our Website will be construed as
          conferring any other license or right expressly, by implication, estoppel, or otherwise to
          any of Elit Care&apos;s or a third party&apos;s Intellectual Property. All rights not
          expressly granted herein are reserved.
        </p>
        <p>
          <em>vi. General Limitation on our Liability related to the Use of our Website</em>
        </p>
        <p>
          Users understand and agree that under no circumstances will we or our subsidiaries,
          affiliates, directors, officers, employees, contractors, subcontractors, Website content
          contributors, or agents be liable for any indirect, special, incidental, or consequential
          damages including, without limitation, any loss of use, loss of profits, loss of data,
          loss of goodwill, cost of procurement of substitute services, or any other indirect,
          special, incidental, or consequential damages resulting from or associated with
          Users&rsquo; use of our Website. This limitation applies regardless of the manner in which
          damages are caused or the theory of liability, whether for breach of contract, tort
          (including negligence and strict liability), or otherwise resulting from (1) the use of,
          or the inability to use, the Website; or (2) the cost of procuring substitute services.
        </p>
        <p>
          <em>vii. Communication</em>
        </p>
        <p>Users confirm that any contact information provided to us will be true and accurate.</p>
        <p>
          If a User reaches out to us, the User consents to us contacting them. Users also consent
          to receive e-mails from us or on our behalf relating to our services. Users may opt out of
          receiving emails from us.&nbsp;&nbsp;
        </p>
        <p>
          In this section vii of part B, &ldquo;User&rdquo; does not include &ldquo;Patient.&rdquo;
          For further information about communication between Patients and the Clinic, please see
          our Privacy Policy.
        </p>
        <p>
          <em>viii. Other Terms of Use of our Website</em>
        </p>
        <p>
          Elit Care may, at our discretion, assign, transfer, or subcontract any of our rights or
          obligations under these Terms to any third party.&nbsp;
        </p>
        <p>
          Our delay in exercising any right or remedy under these Terms shall never operate as a
          waiver of that right or remedy and shall not affect our ability to exercise that right or
          remedy subsequently. We must agree to any waiver in writing.
        </p>
        <p>
          These Terms supersede any other terms previously published by us and any other
          representations or statements made by us to Users, Patients, or an individual User or
          Patient, whether oral, written, or otherwise.
        </p>
        <p>
          <em>ix. Termination of our Website</em>
        </p>
        <p>
          We may, in our sole discretion, terminate or suspend the operation of our Website or
          Users&rsquo; use of our Website without prior notice. We may do so for any reason that we,
          in our sole discretion, determine to be appropriate.
        </p>
      </div>
    </Layout>
  );
};

export default Physio;
