import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as TiktokIcon } from 'assets/icons/tiktok.svg';
import cn from 'classnames';
import React from 'react';
import { useContentful } from 'react-contentful';
import { Link } from 'react-router-dom';
import { routes } from 'utils';
import classes from './styles.module.scss';

export type HeaderPropType = {
  className?: string;
};

const Footer: React.FC<HeaderPropType> = ({ className }) => {
  const { data } = useContentful({
    contentType: 'contactInformation',
  });
  /*eslint-disable */
  const contactInfo = (data as any)?.items?.[0] || {};

  const fbLink = contactInfo?.fields?.facebookLink || '';
  const instaLink = contactInfo?.fields?.instagramLink || '';
  const linkedInLink = contactInfo?.fields?.linkedInLink || '';
  const tiktokLink = contactInfo?.fields?.tiktokLink || '';

  return (
    <footer className={cn(classes.base, className)}>
      <div className={classes.content}>
        <div>
          © {new Date().getFullYear()} Elit.care. All Rights Reserved.
          <br />
          <p className={classes.extraLinks}>
            <Link to={routes.TERMS_OF_SERVICE}>Terms of Service</Link> -{' '}
            <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link>
          </p>
        </div>
        <div className={classes.socialIcons}>
          {fbLink && (
            <a href={fbLink} target="_blank" rel="noreferrer">
              <FacebookIcon />
            </a>
          )}
          {instaLink && (
            <a href={instaLink} target="_blank" rel="noreferrer">
              <InstagramIcon />
            </a>
          )}

          {linkedInLink && (
            <a href={linkedInLink} target="_blank" rel="noreferrer">
              <LinkedInIcon />
            </a>
          )}
          {tiktokLink && (
            <a href={tiktokLink} target="_blank" rel="noreferrer">
              <TiktokIcon />
            </a>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
