import React from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import Helmet from 'react-helmet';
import { ReactComponent as HeroCurve } from 'assets/icons/hero-curve.svg';
import { Footer } from 'components';
import classes from './styles.module.scss';

export type LayoutPropType = {
  className?: string;
  contentClassName?: string;
  curveClassName?: string;
  title: string;
  description?: string;
  heroImage?: string;
  children?: React.ReactNode;
};

const Layout: React.FC<LayoutPropType> = ({
  className,
  contentClassName,
  curveClassName,
  title,
  description,
  heroImage,
  children,
}) => {
  const location = useLocation();
  return (
    <div className={cn(classes.base, className)}>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        <link rel="canonical" href={`https://elit.care${location.pathname || ''}`} />
      </Helmet>
      <div
        id="hero"
        className={classes.hero}
        style={
          heroImage
            ? {
                backgroundImage: `linear-gradient(0deg, rgba(16, 24, 32, 0.5) 0%, rgba(16, 24, 32, 1) 100%), url(${heroImage})`,
              }
            : {}
        }
      >
        <h1>{title}</h1>
        <HeroCurve className={curveClassName} />
      </div>
      <div className={classes.body}>
        <div className={cn(classes.content, contentClassName)}>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
