import React from 'react';
import cn from 'classnames';
import { Layout } from 'components';
import classes from './styles.module.scss';
import { reset } from 'utils/cookieConsent';
// import data from 'data';

const Physio: React.FC = () => {
  return (
    <Layout title="Privacy Policy" className={cn(classes.body)}>
      <div className={classes.content}>
        <p>
          <strong>Privacy Policy</strong>
        </p>
        <p>
          <strong>Date of Last Amendment: January 30, 2022</strong>
        </p>

        <h1>A. Accountability</h1>

        <p>
          1000251423 Ontario Inc. c.o.b. Elit Care (&ldquo;Elit Care&rdquo; &ldquo;the
          Clinic,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; &ldquo;our&rdquo;), located at 412
          Front Street East, Toronto, ON M5A 0H8, provides therapeutic (physiotherapy, massage
          therapy, chiropractic) care.
        </p>
        <p>
          Elit Care&rsquo;s obligations with respect to the confidentiality of Patients&rsquo;
          Personal Health Information are governed by the Personal Health Information Protection Act
          (&ldquo;PHIPA&rdquo;), Ontario&rsquo;s health-care-specific privacy legislation.
        </p>
        <p>
          PHIPA governs how Personal Health Information is collected, used, and disclosed. This
          Privacy Policy explains how we collect, use, disclose, safeguard, and dispose of Personal
          Health Information in compliance with PHIPA.
        </p>

        <h1>B. Definitions, including Personal Health Information</h1>

        <p>
          &ldquo;Custodians&rdquo; are (1) organizations that provide healthcare, and (2) healthcare
          practitioners, including chiropractors, massage therapists, and physiotherapists. Elit
          Care and its therapists are Custodians. With some limited exceptions, only Custodians can
          collect, use, and disclose Patients&rsquo; Personal Health Information.
        </p>
        <p>
          &ldquo;Agents&rdquo; are individuals or companies authorized by a Custodian to perform
          services or activities in respect of Personal Health Information on the Custodian&rsquo;s
          behalf. Agents include administrative employees working for healthcare organizations.
        </p>
        <p>
          &ldquo;Electronic Service Providers&rdquo; are companies that supply services that enable
          Custodians to collect, use, modify, disclose, retain, or dispose of Personal Health
          Information electronically (i.e., third-party IT providers). Electronic Service Providers
          are prohibited from using any Personal Health Information to which they have access in the
          course of providing services to Custodians, except as necessary in the course of providing
          services, and an Electronic Service Provider cannot disclose Personal Health Information.
          An Electronic Service Provider must ensure its employees and any other persons acting on
          its behalf comply with the Electronic Service Provider&rsquo;s legal obligations to
          protect the privacy of Personal Health Information.
        </p>
        <p>
          &ldquo;Patients&rdquo; are individuals who receive healthcare. In this Privacy Policy,
          Patients means individuals who receive therapeutic care at our Clinic.
        </p>
        <p>
          &ldquo;Users&rdquo; are visitors to our Website. A User is not a Patient unless and until
          they receive therapeutic care at our Clinic. The limited information a User provides to us
          by way of our Website (name, email address, telephone number) is not Personal Health
          Information unless and until the User becomes an Elit Care Patient and a record (i.e.,
          Patient File) is created for that Patient. At that point, because the Patient&rsquo;s
          contact information would be included in the Patient File, it would be Personal Health
          Information.
        </p>
        <p>
          &ldquo;Personal Health Information&rdquo; is &ldquo;Identifying Information&rdquo; about
          an individual if the information
        </p>
        <ul>
          <li>
            Relates to the individual&rsquo;s physical or mental condition, including family medical
            history.
          </li>
          <li>Relates to the provision of health care to the individual.</li>
          <li>Is a plan of service for the individual.</li>
          <li>
            Relates to payments, or eligibility for, healthcare or for coverage for healthcare.
          </li>
          <li>Is the individual&rsquo;s health number.</li>
          <li>
            Identifies a healthcare provider or a substitute decision-maker for the individual.
          </li>
        </ul>
        <p>
          &ldquo;Identifying Information&rdquo; includes information that identifies a person or
          could be used, either alone or with other information, to identify a person.
        </p>

        <h1>
          C. The Personal Health Information We Collect and&nbsp; Use and Why We Collect and Use It
        </h1>

        <p>
          For PHIPA purposes, &ldquo;collect&rdquo; and &ldquo;use&rdquo; have their commonly
          understood meanings
        </p>
        <ul>
          <li>
            &ldquo;Collect&rdquo; is the gathering, acquiring, receiving, or obtaining of Personal
            Health Information by any means from any source.
          </li>
          <li>
            &ldquo;Use&rdquo; means to handle or deal with (read, refer to, add to, consult with a
            Patient or another Elit Care therapist with respect to, etc.) Personal Health
            Information in the course, or for the purpose, of providing care or carrying out
            administrative tasks necessary to provide care (booking appointments, processing
            payments, etc.).
          </li>
        </ul>
        <p>
          The Personal Health Information we collect and use includes everything in the
          Patient&rsquo;s File: the Patient&rsquo;s name, address, email address, and telephone
          number (i.e., contact information), as well as Elit Care therapists&rsquo; clinical notes,
          and any notes or correspondence from, for example, a Patient&rsquo;s family or referring
          physician or a therapist who previously provided care.
        </p>
        <p>
          Information related to how a Patient pays for care (e.g., credit card information) and
          appointment dates (both in the Patient File and the Clinic&rsquo;s booking system) are
          also Personal Health Information.
        </p>
        <p>
          We collect and use Personal Health Information to provide healthcare to Patients. We do
          not collect or use more Personal Health Information than is reasonably necessary to
          satisfy the purpose of the collection or use, i.e., to provide care.
        </p>
        <p>
          We also use Patients&rsquo; contact information for general administrative purposes such
          as processing payments and contacting Patients to confirm or reschedule appointments or
          provide information beneficial to Patients such as healthcare tips and Clinic events they
          may benefit from attending. Patients can opt out of any email marketing material we may
          send them.
        </p>
        <p>
          Our use of Personal Health Information includes the creation of Personal Health
          Information in the form of, for example, therapists&rsquo; clinical notes entered in a
          Patient File.
        </p>
        <p>
          In limited circumstances, as permitted by PHIPA, we may use Personal Health Information
          without consent for the following purposes
        </p>
        <ol>
          <li>Planning or delivering programs or services.</li>
          <li>
            Risk management, error management, or activities to improve or maintain the quality of
            care or any related program or service.
          </li>
          <li>Educating Agents about their roles in support of the provision of healthcare.</li>
          <li>
            Obtaining payment or processing, monitoring, verifying, or reimbursing healthcare
            claims.
          </li>
          <li>Research, if specific requirements and conditions are met.</li>
          <li>If otherwise permitted or required by law.</li>
        </ol>
        <h1>D. Consent</h1>
        <p>
          The general rule is that a Custodian must obtain a Patient&rsquo;s consent to collect,
          use, and disclose Personal Health Information, although PHIPA allows the collection, use,
          or disclosure without consent in some limited circumstances.
        </p>
        <p>
          A Patient&rsquo;s consent to the collection, use, or disclosure of Personal Health
          Information by a Custodian may be express or implied.
        </p>
        <p>
          Express consent is consent that has been given explicitly (clearly and unmistakably),
          either verbally or in writing.
        </p>
        <p>
          Implied consent is consent that a Custodian concludes has been given based on a
          Patient&rsquo;s action or inaction in particular factual circumstances.
        </p>
        <p>
          Example 1: If a Patient completes a questionnaire to facilitate consultation with a
          therapist about treatment, the therapist can infer consent to the Clinic&rsquo;s
          collection and use of the Personal Health Information provided in the questionnaire.
        </p>
        <p>
          Example 2: If a Patient proffers a credit card to pay for services, that implies consent
          to the disclosure of the information on the credit card (which is Personal Health
          Information) to the credit card payment processor.&nbsp;
        </p>
        <p>All consent, whether express or implied, must be</p>
        <ul>
          <li>
            Knowledgeable, which means the Patient knows why the Custodian collects, uses, and
            discloses their Personal Health Information, and that they may give or withhold this
            consent
          </li>
          <li>Voluntary (not obtained through deception or coercion)</li>
          <li>Related to the information in question, and</li>
          <li>
            Given by the Patient him/herself (there are exceptions to this that do not normally
            arise in the context of the healthcare provided by Elit Care).
          </li>
        </ul>
        <p>Express consent is always required when</p>
        <ul>
          <li>
            Personal Health Information is disclosed to a person or an organization that is not a
            Custodian, such as an insurance company
          </li>
          <li>
            Personal Health Information is disclosed by one Custodian to another Custodian for a
            purpose other than providing or assisting in providing healthcare.
          </li>
          <li>
            A Custodian wishes to use Personal Health Information for fundraising, marketing,
            advertising,&nbsp; or research purposes.
          </li>
        </ul>
        <p>
          Except as permitted by PHIPA and as disclosed in this Policy, Elit Care only collects,
          uses, and discloses Personal Health Information with the requisite Patient Consent.
        </p>

        <h1>E. Our Additional Responsibilities Concerning Personal Health Information</h1>
        <p>
          In addition to our responsibility to obtain consent before collecting Personal Health
          Information, as a Custodian, Elit Care is required to
        </p>
        <ol>
          <li>
            Develop and implement practices that comply with PHIPA&rsquo;s requirements, including
            taking reasonable steps to ensure that Personal Health Information is protected against
            theft, loss, and unauthorized use or disclosure, as well as against unauthorized
            copying, modification or disposal.
          </li>
          <li>
            Ensure that personal health information is as accurate, up-to-date, and as complete as
            is necessary for the purposes for which it is used.
          </li>
          <li>
            Ensure that Personal Health Information records (i.e., Patient Files) are retained,
            transferred, and disposed of in a secure manner.&nbsp;
          </li>
          <li>
            Retain Personal Health Information for as long as needed to allow a Patient to exhaust
            any legal recourse regarding an access request. (PHIPA does not establish specific
            retention periods for Personal Health Information.)
          </li>
          <li>
            Designate a contact person who is authorized to facilitate compliance with PHIPA, ensure
            Agents are appropriately informed of their PHIPA duties, respond to inquiries about the
            Custodian&rsquo;s information practices, respond to access and correction requests, and
            receive complaints.
          </li>
          <li>
            Provide a written statement that is readily available to the public and describes the
            Custodian&rsquo;s information practices, how to reach the contact person, how to obtain
            access to or request a correction of a record of Personal Health Information, and how to
            make a complaint to the Custodian and to the Information and Privacy Commissioner of
            Ontario.
          </li>
        </ol>
        <p>
          This Privacy Policy is how Elit Care complies with no. 6. Elit Care complies with nos. 1
          through 5 as outlined in this Policy. (Note, however, that Elit Care does not publicly
          disclose the methods and resources it uses to protect electronically-stored Personal
          Health Information from theft, loss, and unauthorized use (e.g., hacking) because doing so
          would undermine the security of the Personal Health Information.)
        </p>

        <h1>F. Safeguards</h1>

        <p>
          We use healthcare-standard administrative, electronic (HTTPS over TLS encryption), and
          physical security measures to prevent unauthorized access to your Personal Health
          Information.
        </p>

        <h1>G. Accuracy of Personal Health Information</h1>

        <p>
          We make all reasonable efforts to ensure that Personal Health Information is accurate,
          complete, and up to date. This includes that every appointment, and the treatment
          provided, is recorded in Patient Files. Care-related information relayed by Patients is
          recorded by therapists in Patient Files, as are test results, and therapists&rsquo;
          clinical observations, impressions, etc.
        </p>
        <p>
          For purposes of accuracy, we may require Patients to confirm/update information from time
          to time. We are not responsible for the inaccuracy or incompleteness of information if a
          Patient fails to update us in a timely manner.
        </p>

        <h1>H. Retention of Personal Health Information</h1>

        <p>
          We retain Personal Health Information as required by PHIPA, i.e., for as long as a Patient
          remains a Patient of the Clinic and then for a period of time sufficient to ensure the
          information is available to be transferred to another clinic or the Patient&rsquo;s
          physician. We will also retain Personal Health Information for as long as necessary to
          allow a Patient to exhaust any legal recourse regarding an access request or if the
          information may be required in a legal proceeding, as long as, in the latter case, we are
          advised of that possibility.
        </p>
        <p>
          When we dispose of Personal Health Information, we do so in a way that prevents a privacy
          breach, i.e., by securely shredding paper files and completely and permanently deleting
          electronic records.
        </p>

        <h1>I. Elit Care&rsquo;s Designated Contact Person</h1>

        <p>
          The Director is Elit Care&rsquo;s Designated Contact Person authorized to facilitate
          compliance with PHIPA, ensure Agents are appropriately informed of their duties, respond
          to inquiries about the Clinic&rsquo;s information practices, respond to access and
          correction requests, and receive complaints.
        </p>
        <p>
          The Director may be contacted by email to{' '}
          <a href="http://admin@elit.care" target="_blank" rel="noreferrer">
            admin@elit.care
          </a>
          . (State &ldquo;Personal &amp; Confidential for the Director - PHIPA&rdquo; in the subject
          line.)
        </p>
        <p>
          Note that although Elit Care and individual therapists are all Custodians, the Clinic has
          overall and ultimate responsibility for PHIPA compliance. This has a number of
          consequences, of which the most relevant to Patients is that only the Clinic through the
          Director can disclose a Patient&rsquo;s Personal Health Information to anyone, including
          the Patient and the Patient&rsquo;s physician, not a Clinic therapist or Agent.
        </p>
        <p>
          All requests for the disclosure of a Patient&rsquo;s Personal Health Information must be
          made in writing (email) to the Director. All requests must include proof of the
          Patient&rsquo;s consent to the disclosure. THERAPISTS ARE NOT AUTHORIZED TO DISCLOSE
          PERSONAL HEALTH INFORMATION, I.E., THE CONTENTS OF A PATIENT FILE, TO ANYONE, INCLUDING
          THE PATIENT.
        </p>
        <p>
          Patients have the right to review the contents of their Patient Files and to request, in
          writing, that inaccurate or incomplete information is corrected.
        </p>
        <p>
          The Clinic will correct Personal Health Information in a Patient&rsquo;s file where the
          Patient demonstrates, to the satisfaction of the Director, that the Information is
          inaccurate or incomplete for the purposes for which the Clinic uses the information and
          the Patient provides the information necessary to correct the record.
        </p>
        <p>
          The Clinic may refuse to correct Personal Health Information in a Patient&rsquo;s file if,
          among other reasons, the particular piece of Information consists of a therapist&rsquo;s
          professional opinion or an observation that the therapist made in good faith. If a
          correction is refused, the Director will inform the Patient of the refusal, the reasons
          for the refusal, the Patient&rsquo;s right to file a complaint regarding the refusal to
          the Office of the Information and Privacy Commissioner, and the Patient&rsquo;s right to
          attach a statement of disagreement to the Patient File.
        </p>

        <h1>J. Complaints</h1>

        <p>
          Complaints that Elit Care&rsquo;s collection, use, or disclosure of Personal Health
          Information is not PHIPA-compliant may be made in writing to the Director or the Office of
          the Information and Privacy Commissioner of Ontario, 2 Bloor Street East, Suite 1400,
          Toronto, ON M4W 1A8.
        </p>
        <h1>K. Disclosure of Personal Health Information</h1>
        <p>
          PHIPA permits Custodians to disclose Personal Health Information (1) with the
          Patient&rsquo;s express consent, (2)&nbsp; on the basis of implied consent in certain
          circumstances, and (3) without consent in others.
        </p>
        <p>
          Other than in extraordinary situations (see section M) or as described in this Policy,
          Elit Care does not and will not disclose Personal Health Information to anyone who is not
          an Elit Care therapist, Agent, or Electronic Service Provider. (Note that Elit Care does
          not disclose Personal Health Information to Electronic Service Providers. Rather, in order
          to maintain the security, etc. of the Clinic&rsquo;s electronic Patient Files, incidental
          access to Personal Health Information by an Electronic Service Provider may be inevitable.
          Also note that Electronic Service Providers are legally bound by PHIPA&rsquo;s
          confidentiality obligations.)
        </p>
        <p>
          ALL REQUESTS FOR DISCLOSURE OF A PATIENT&rsquo;S PERSONAL HEALTH INFORMATION MUST BE MADE
          IN WRITING TO THE DIRECTOR AT{' '}
          <a href="http://admin@elit.care" target="_blank" rel="noreferrer">
            ADMIN@ELIT.CARE
          </a>{' '}
          (STATE &ldquo;PERSONAL &amp; CONFIDENTIAL FOR THE DIRECTOR - PHIPA&rdquo; IN THE SUBJECT
          LINE) BY THE PATIENT OR WITH THE PATIENT&rsquo;S EXPRESS (EXPLICIT, NOTARIZED) WRITTEN
          CONSENT. Without the Patient&rsquo;s consent, Elit Care will only disclose Personal Health
          Information if required or permitted to do so by law.
        </p>
        <p>
          We do not disclose more Personal Health Information than is reasonably necessary to
          satisfy the purpose of the disclosure.
        </p>
        <p>We do not sell Patients&rsquo; Personal Health Information.</p>

        <h1>L. Internal Access to Personal Health Information</h1>

        <p>
          Access to Personal Health Information is limited to Elit Care therapists and Agents (i.e.,
          employees) who require it to (i) provide care, (ii) schedule, reschedule, or cancel
          appointments, (iii) administratively maintain Patient Files, (iv) process payments and
          operate our business, and (v) provide such other Patient support as may be appropriate.
          The Personal Health Information made available to employees is limited to what they need
          to do their jobs.
        </p>

        <h1>M. External Disclosure of Personal Health Information</h1>

        <p>
          Other than in compliance with PHIPA and as detailed in this Policy, Elit Care does not
          disclose Personal Health Information to third parties.
        </p>
        <p>
          Elit Care does not disclose Personal Health Information to Electronic Service Providers.
          However, in order to maintain the security, etc. of the Clinic&rsquo;s systems and
          electronic Patient Files, incidental access to Personal Health Information by an
          Electronic Service Provider is often inevitable. Note that Electronic Service Providers
          are legally bound by PHIPA&rsquo;s confidentiality obligations.
        </p>
        <p>
          Disclosure of Personal Health Information without consent is permitted by PHIPA when a
          regulated health professional* believes disclosure is necessary to eliminate or reduce a
          significant risk of serious bodily harm to themselves or others. The following
          circumstances may warrant the disclosure of information to reduce or eliminate the risk of
          harm
        </p>
        <ul>
          <li>
            There is a clear risk to an identifiable person or group of
            persons,&nbsp;&nbsp;&nbsp;&nbsp;
          </li>
          <li>There is a risk of serious bodily harm or death, and</li>
          <li>The danger is imminent.&nbsp;&nbsp;</li>
        </ul>
        <p>* Elit Care therapists are regulated health professionals.</p>

        <h1>N. Data Breaches</h1>

        <p>
          While we take industry-standard steps to secure Personal Health Information, no security
          measures are perfect or impenetrable, and no method of data storage or transmission can be
          guaranteed against any interception or other misuse. In particular, any information stored
          online is vulnerable to interception and misuse by unauthorized parties.
        </p>
        <p>
          In the event of a data breach that poses a real risk of significant harm to Patients, we
          will take the following steps
        </p>
        <ul>
          <li>
            Notify Patients of the breach if their Personal Health Information was or may have been
            accessed or affected.
          </li>
          <li>
            If known, advise Patients of the specific Personal Health Information that was or may
            have been accessed or affected.
          </li>
          <li>
            Advise Patients of steps they can take to reduce the risk of harm from the breach, e.g.,
            changing passwords or monitoring accounts.
          </li>
          <li>
            Advise Patients when the breach has been addressed, and the risk to data has abated.
          </li>
          <li>
            Advise applicable government authorities of the breach if required to do so by law.
          </li>
        </ul>
        <h1>O. The Personal Health Information of Minors</h1>
        <p>
          PHIPA empowers capable minors to consent to a Custodian&rsquo;s collection, use, and
          disclosure of Personal Health Information, regardless of age. &ldquo;Capable&rdquo; in
          this context means the minor is able to understand information relevant to deciding
          whether or not to consent to the collection, use, or disclosure of their Personal Health
          Information, and to appreciate the reasonably foreseeable consequences of giving/not
          giving, withholding, or withdrawing their consent.
        </p>
        <p>
          In the case of minors under the age of 16, a parent or other person who is lawfully
          entitled to give or refuse consent may also give, withhold, or withdraw consent. However,
          if there is a conflict between a capable minor under 16 years of age and the person
          entitled to act as the child&rsquo;s substitute decision-maker, the decision of the
          capable child regarding giving, withholding, or withdrawing consent prevails.
        </p>
        <p>
          Because the care Elit Care provides is not often a source of controversy or dispute
          between a minor and a parent or legal guardian, we only collect, use, and disclose
          Personal Health Information from and about minors with parental consent. Cases of minors
          requesting care and not wishing to provide parental consent will be considered on an
          individual basis.
        </p>
        <h1>P. Our Collection, Use, and Disclosure of non-Personal Health Information</h1>
        <p>
          Each time a User visits our Website or otherwise connects with us electronically, we
          collect information about the User&rsquo;s preferences or device that is used to make our
          Website work as Users expect it to.
        </p>
        <p>
          Examples of the information we collect or may collect include the date and time of a visit
          or connection, the User&rsquo;s browser type, the User&rsquo;s Internet Service Provider
          and IP address, the site the User visited before or after our Website (&ldquo;navigation
          history,&rdquo; &ldquo;referring site&rdquo;), and the pages on our site that were viewed.
          In isolation, a single piece of such information does not identify a User but, in some
          cases, it could be used in combination with other information to identify an individual
          user, particularly if the user provided us with contact information at the time of their
          visit or connection.
        </p>
        <p>
          Website Users who are not Patients consent, by their continued use of our Website, to the
          collection of information that does not identify them (but could identify them if combined
          with other information). In no circumstances, however, would any of that identifying
          information relate to the User&rsquo;s healthcare or state of health, i.e., it would not
          be Personal Health Information.
        </p>
        <p>
          Website Users who are not Patients and who do not want us to collect or use information of
          any kind are instructed not to browse our Website. A Website User unable to find an
          acceptable alternative to one of our services and having no choice but to use our
          services, but who still does not wish to provide us with Personal Health Information, may
          contact the Director at{' '}
          <a href="http://admin@elit.care" target="_blank" rel="noreferrer">
            admin@elit.care
          </a>
          . (State &ldquo;Personal &amp; Confidential for the Director - PHIPA&rdquo; in the subject
          line.) Note, however, we cannot provide care without collecting and using Personal Health
          Information, so to become a Patient of the Clinic, consent will be required.
        </p>
        <p>
          Website Users who are also Patients cannot, by using our Website, provide us with Personal
          Health Information that they have not previously provided to us with consent.&nbsp;
        </p>
        <p>
          In addition to using non-Personal Health Information to optimize our Website, we use this
          information for our internal security audit log, usage and trend analysis, system
          administration, and to gather aggregate demographic information about our Website User
          base for market identification and related purposes. We may share this information with
          Electronic Service Providers that provide services to us, including analysis, storage, and
          data aggregation/organization.
        </p>
        <p>
          <em>Cookies</em>
        </p>
        <p>
          Cookies are small text files that are placed on users&rsquo; computers by websites. They
          are widely used to allow websites to work properly and be more user-friendly, remember
          user preferences, improve user experience, and provide website owners with information
          they use for legitimate business purposes. A description of what cookies are and how they
          work can be found
          <a href="https://www.privacypolicies.com/blog/cookies/" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </p>
        <p>We use cookies for all of the reasons listed.</p>
        <p>
          Some cookies placed by our Website may be from Electronic Service Providers that provide
          us with analytics and other services. The information collected helps us understand how
          Users interact with our Website.
        </p>
        <p>
          <em>Google</em>
        </p>
        <p>
          We use the Google Analytics suite of tools. Google (Alphabet Inc.) uses cookies to help us
          better understand how our Website is being used including the number of users, session
          statistics (the pages visited, how long users stay on the site), approximate geolocation,
          and browser and device information.
        </p>
        <p>
          In particular, we use Google Tag Manager, a Google Analytics tool, to manage tags.
          Marketing tags are segments of code on a website that track user actions and collect data.
          Specific actions on a website trigger tags to collect data about the activity and send it
          to an analytics tool. Different tags serve different functions: Pageview Tags monitor
          visits to pages on a website; Conversion Tags track, for example, each time a sale is made
          or a form is filled out.
        </p>
        <p>
          We use the Google Search Console to obtain search-engine-related data to help us optimize
          traffic to our Website. Examples of search-engine-relate data include user queries and the
          number of times our site&rsquo;s URLs appear in search results (impressions), along with
          post-click data about site engagement like bounce rates and e-commerce conversion rates.
        </p>
        <p>
          Google Maps also uses cookies to help us understand the general geographic locales
          visitors to our Website are from (but not individual addresses or other personally
          identifying information).
        </p>
        <p>
          Google policies require that no data be passed to Google that Google could use or
          recognize as personally identifying information. More information about Google&rsquo;s
          policies is
          <a
            href="https://support.google.com/analytics/answer/6366371?hl=en#zippy=%2Cin-this-article"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <p>
          <em>Ad Services</em>
        </p>
        <p>
          We use Google Ads to highlight our Website when users use Google&rsquo;s search engine. We
          do not disclose personally identifying information when we use Google Ads.
        </p>
        <p>
          We do not now but in future may use Meta Ads to advertise on, for example, Facebook and
          Instagram. We may disclose aggregate, anonymous data about visits to our Website to Meta
          but we do not disclose personally identifying information.
        </p>
        <p>
          <em>Other Service Providers</em>
        </p>
        <p>In addition to Google, the following companies provide services to us:&nbsp;</p>
        <ul>
          <li>
            <a href="https://jane.app/legal/privacy-policy" target="_blank" rel="noreferrer">
              Jane
            </a>{' '}
            provides our booking portal and other electronic administrative services, including
            storing contact information that is Personal Health Information in the case of Patients.
          </li>
          <li>
            <a
              href="https://aws.amazon.com/compliance/data-privacy-faq/"
              target="_blank"
              rel="noreferrer"
            >
              Amazon Web Services
            </a>{' '}
            (AWS) hosts our Website and email server.
          </li>
        </ul>
        <p>
          We provide these service providers with Personal Health Information. Both are Electronic
          Service Providers. We acknowledge that we are ultimately responsible for the security of
          Personal Health Information provided to us. We are satisfied that these providers properly
          protect and lawfully use Personal Health Information in their possession. You can read
          each company&rsquo;s privacy policy by clicking on their names.
        </p>
        <p>&zwj;</p>
        <p>
          <em>&zwj;Other User-Tracking Technologies</em>
        </p>
        <p>
          We do not now but in future may use web beacons (sometimes called trackers). Web beacons
          allow the owner of a website to count the number of users who click on an advertisement
          for its product or service on a third-party site (in order to assess the value of
          advertising on that third-party site). Web beacons collect only specific information, such
          as a cookie number, time and date of visit, and a description of the page on which the web
          beacon is installed. Personal information is not collected.
        </p>
        <p>
          It is not possible to opt out of web beacons used on web pages. However, because web
          beacons are used together with cookies, their use can be limited by deleting cookies or
          changing your browser&rsquo;s cookie settings.
        </p>
        <p>
          &zwj;<em>Managing Cookies Settings</em>
        </p>
        <p>
          You can manage your cookies settings to clear or block specific cookies or all cookies.
          However, if you block all of the cookies that we use, it may affect your user experience
          or result in our Website becoming unusable. You can access your cookie settings via your
          browser settings. Please keep in mind that by clearing all cookies, all website
          preferences will be lost.
        </p>

        <h1>Q. Amendments to this Privacy Policy</h1>

        <p>
          We amend this Privacy Policy from time to time. If the Date of Last Amendment is later
          than the last time you read it, please review this Policy again. Continued use of our
          website and/or the purchase of services from us constitutes your consent to the
          collection, use, and disclosure of your Personal Health Information in accordance with
          this Policy.
        </p>
      </div>
      <p style={{ textAlign: 'center' }}>
        To reset your consent to using cookies,{' '}
        <a
          style={{ textDecoration: 'underline' }}
          onClick={() => {
            reset();
            location.reload();
          }}
        >
          click here
        </a>
      </p>
    </Layout>
  );
};

export default Physio;
