import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import cn from 'classnames';
import { ContactBlock, Layout, StaffBlock } from 'components';
import React, { useEffect, useMemo } from 'react';
import { useContentful } from 'react-contentful';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../utils';
import classes from './styles.module.scss';

export type ServiceType = {
  fields: {
    name: string;
    shortDescription: string;
    icon: {
      fields: {
        file: {
          url: string;
        };
      };
    };
    slug: string;
    pageContent: any;
    heroImage: {
      fields: {
        file: {
          url: string;
        };
      };
    };
    order: number;
    visibleInNavigationBar: boolean;
  };
};

const Physio: React.FC = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  // Fetching service

  const { data: serviceResult, error: serviceError } = useContentful({
    contentType: 'service',
    query: {
      'fields.slug': slug,
      limit: 1,
    },
  });
  /*eslint-disable */
  const service = useMemo(
    () => ((serviceResult as any)?.items || [])[0] as ServiceType,
    [serviceResult]
  );

  useEffect(() => {
    if (serviceError) navigate(routes.HOME);
  }, [serviceError]);

  const pageContentHtml = useMemo(
    () => documentToHtmlString(service?.fields?.pageContent),
    [service?.fields?.pageContent]
  );

  return (
    <Layout
      title={service?.fields?.name}
      heroImage={`https:${service?.fields?.heroImage?.fields?.file?.url}`}
      className={cn(classes.body)}
      contentClassName={cn(classes.rootContent)}
      curveClassName={cn(classes.curve)}
    >
      <StaffBlock serviceSlug={service?.fields?.slug} />
      <div className={classes.content} dangerouslySetInnerHTML={{ __html: pageContentHtml }}></div>

      <ContactBlock />
    </Layout>
  );
};

export default Physio;
