import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import cn from 'classnames';
import { ContactBlock, Layout, StaffBlock } from 'components';
import React, { useMemo } from 'react';
import { useContentful } from 'react-contentful';
import classes from './styles.module.scss';

type AboutUsDataType = {
  fields: {
    heroImage: {
      fields: {
        file: {
          url: string;
        };
      };
    };
    content: any;
  };
};

const AboutUs: React.FC = () => {
  const { data: pageResult } = useContentful({
    contentType: 'aboutUs',
  });

  /*eslint-disable */
  const pageData = useMemo(
    () => ((pageResult as any)?.items || [])[0] as AboutUsDataType,
    [pageResult]
  );

  const contentHtml = useMemo(
    () => documentToHtmlString(pageData?.fields?.content),
    [pageData?.fields?.content]
  );

  return (
    <Layout
      title="About Us"
      heroImage={`https:${pageData?.fields?.heroImage?.fields?.file?.url}`}
      className={cn(classes.body)}
    >
      <div className={classes.content} dangerouslySetInnerHTML={{ __html: contentHtml }}></div>

      {/* eslint-disable-next-line  */}
      <StaffBlock title="Elit Care Team" showAll />

      <ContactBlock />
    </Layout>
  );
};

export default AboutUs;
