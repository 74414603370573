import elitLogoFull from 'assets/logos/elit-black-logo-transparent-bg.png';
import elitLogo from 'assets/logos/elit-white-logo-transparent-bg.png';
import cn from 'classnames';
import { Button } from 'components';
import { useScrollPosition, useWindowSize } from 'hooks';
import { orderBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useContentful } from 'react-contentful';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'utils';
import { ServiceType } from '../../pages/Service';
import classes from './styles.module.scss';

export type HeaderPropType = {
  className?: string;
  animateFill?: boolean;
  onLinkClick?: (linkId: string) => void;
};

const Navbar: React.FC<HeaderPropType> = ({ className, onLinkClick }) => {
  const { data } = useContentful({
    contentType: 'contactInformation',
  });
  /*eslint-disable */
  const contactInfo = (data as any)?.items?.[0] || {};
  const janeAppLink = contactInfo?.fields?.janeAppMainUrl || '';

  const [full, setFull] = useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const { data: serviceResult } = useContentful({
    contentType: 'service',
  });
  /*eslint-disable */
  const services = useMemo(
    () =>
      orderBy(
        ((serviceResult as any)?.items || []) as ServiceType[],
        ['fields.order'],
        ['asc']
      ).filter((s) => s.fields.visibleInNavigationBar) as ServiceType[],
    [serviceResult]
  );

  useScrollPosition(({ currPos }) => {
    const hero: HTMLElement | null = document.querySelector('#hero');
    if (!hero) return;

    if (currPos.y < -hero.offsetHeight) {
      if (!full) setFull(true);
    } else {
      if (full) setFull(false);
    }
  });

  useEffect(() => {
    document.body.style.overflow = mobileMenuOpen ? 'hidden' : 'auto';
  }, [mobileMenuOpen]);

  const scrollToTop = () => {
    const node = document.getElementById('hero');
    node?.scrollIntoView({ behavior: 'smooth' });
  };

  const openBookingApp = () => {
    window.open(janeAppLink, '_newtab');
  };

  const handleLinkClick = (linkUrl: string, redirect = true) => {
    onLinkClick?.(linkUrl);
    if (redirect) {
      navigate(linkUrl);
    }

    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  const scrollToContact = () => {
    const contactSection = document.querySelector('#contact-us-info-block');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
      setMobileMenuOpen(false);
    }
  };

  const isMobile = windowSize.width < 1256;

  const menuItems = (
    <ul>
      {(services || []).map((service) => {
        return (
          <li
            key={service.fields.slug}
            onClick={() => handleLinkClick(service.fields.slug)}
            className={cn({
              [classes.activeLink]: location.pathname.includes(service.fields.slug),
            })}
          >
            {service.fields.name}
          </li>
        );
      })}
      <li
        onClick={() => handleLinkClick(routes.ABOUT_US)}
        className={cn({ [classes.activeLink]: location.pathname.includes(routes.ABOUT_US) })}
      >
        About Us
      </li>
      <li
        onClick={() => scrollToContact()}
        className={cn({ [classes.activeLink]: location.pathname.includes(routes.CONTACT_US) })}
      >
        Contact Us
      </li>
    </ul>
  );

  return (
    <nav className={cn(classes.base, { [classes['base--full']]: full }, className)}>
      <Link
        to={routes.HOME}
        className={classes.logoLink}
        onClick={location.pathname === '/' ? () => scrollToTop() : (a) => a}
      >
        <img className={classes.logo} src={full ? elitLogoFull : elitLogo} />
      </Link>

      <div className={classes.rightContent}>
        {!isMobile && (
          <>
            {menuItems}
            <Button
              className={classes.bookButton}
              color={full ? 'black' : 'primary'}
              size={full ? 'small' : 'normal'}
              onClick={openBookingApp}
            >
              Book Now
            </Button>
          </>
        )}
        {isMobile && (
          <div
            className={cn(classes.mobileMenuIcon, {
              [classes['mobileMenuIcon--toggled']]: mobileMenuOpen,
            })}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <div />
            <div />
            <div />
          </div>
        )}
        <div className={cn(classes.mobileMenu, { [classes['mobileMenu--open']]: mobileMenuOpen })}>
          {menuItems}
          <Button
            className={cn(classes.bookButton, classes['bookButton--mobile'])}
            color="black"
            size="big"
            onClick={openBookingApp}
          >
            Book Now
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
