import React from 'react';
import cn from 'classnames';
import classes from './styles.module.scss';

export type HeaderPropType = {
  className?: string;
  color?: 'primary' | 'black' | 'white' | 'contrast' | 'outline';
  variant?: 'default' | 'square';
  size?: 'normal' | 'small' | 'big';
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  children?: JSX.Element | string;
};

const Button: React.FC<HeaderPropType> = ({
  className,
  size = 'normal',
  color = 'primary',
  variant = 'default',
  onClick,
  children,
}) => {
  return (
    <button
      className={cn(
        classes.base,
        classes[`color-${color}`],
        classes[`size-${size}`],
        classes[`variant-${variant}`],
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
