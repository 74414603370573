import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useContentful } from 'react-contentful';
import { ServiceType } from '../../pages/Service';
import classes from './styles.module.scss';

export type TherapistDataType = {
  fields: {
    bio: string[];
    image?: {
      fields: {
        file: {
          url: string;
        };
      };
    };
    instagramLink?: string;
    janeAppLink?: string;
    name: string;
    visible: boolean;
    order?: number;
    servicesOffered?: ServiceType[];
  };
};
export type StaffBlockPropType = {
  className?: string;
  title?: string;
  serviceSlug?: string;
  showAll?: boolean;
};

const StaffBlock: React.FC<StaffBlockPropType> = ({ className, title, serviceSlug, showAll }) => {
  const bookTherapist = (url: string) => {
    window.open(url, '_newtab');
  };

  // Fetching therapists
  const { data: therapistsResult } = useContentful({
    contentType: 'therapist',
  });
  /*eslint-disable */
  const therapists = useMemo(
    () =>
      orderBy(
        ((therapistsResult as any)?.items || [])
          ?.filter(
            (d: TherapistDataType) =>
              (showAll ||
                !!d.fields?.servicesOffered?.find((s) => s.fields?.slug === serviceSlug)) &&
              d.fields.visible
          )
          .map((d) => ({ fields: { ...d.fields, order: d.fields.order || 99 } })),
        ['fields.order'],
        ['asc']
      ),

    [therapistsResult, serviceSlug, showAll]
  );

  const { data: adminsResult } = useContentful({
    contentType: 'administrator',
  });
  const admins = useMemo(
    () =>
      orderBy(
        ((adminsResult as any)?.items || [])
          ?.filter((d: TherapistDataType) => showAll && d.fields.visible)
          .map((d) => ({ fields: { ...d.fields, order: d.fields.order || 99 } })),
        ['fields.order'],
        ['asc']
      ),

    [adminsResult, serviceSlug, showAll]
  );
  const allStaff = useMemo(() => [...therapists, ...admins], [therapists, admins]);

  return (
    <div id="staff-info-block" className={classes.base}>
      <div className={classes.inner}>
        <h2>{title ? title : 'Book your session now'}</h2>
        <div className={classes.staffContainer}>
          {!allStaff?.filter((t) => t.fields.visible)?.length && <p>Coming soon</p>}
          {allStaff
            .filter((t) => t.fields.visible)
            .map((therapist) => {
              const { name, janeAppLink, instagramLink, bio = [] } = therapist.fields || {};

              return (
                <div
                  key={name}
                  className={classes.therapist}
                  // style={{ backgroundImage: `url(${therapist.image as string})` }}
                  onClick={janeAppLink ? () => bookTherapist(janeAppLink) : (a) => a}
                >
                  <div className={classes.tileInner}>
                    <div
                      className={classes.profilePicture}
                      style={{
                        backgroundImage: `url(${therapist.fields?.image?.fields?.file?.url})`,
                      }}
                    />
                    <p className={classes.name}>
                      {name}
                      {instagramLink && (
                        <a
                          href={instagramLink}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <InstagramIcon width={24} />
                        </a>
                      )}
                    </p>

                    <div className={classes.line} />
                    <p className={classes.bio}>
                      {bio.map((b) => (
                        <>
                          {b}
                          <br />
                        </>
                      ))}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default StaffBlock;
