import { Button, Navbar } from 'components';
import { AboutUs, Home, PrivacyPolicy, Service, TermsOfService } from 'pages';
import React, { useLayoutEffect, useState } from 'react';
import { ContentfulClient, ContentfulClientParams, ContentfulProvider } from 'react-contentful';
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { routes } from 'utils';
import { allowTracking, isTrackingAllowed } from 'utils/cookieConsent';
import classes from './App.module.scss';

/*eslint-disable */

const contentfulOptions: ContentfulClientParams = {
  accessToken: 'AFuDNNwwcAL9atGIPpNlf0MBKQ_DxNq4NK9KWN7Dc5I',
  space: '6e5fjx92mqr2',
};

const contentfulClient = new (ContentfulClient as any)(contentfulOptions);

export const App = () => {
  const C: any = ContentfulProvider;
  return (
    <main className={classes.base}>
      <C client={contentfulClient}>
        <Router>
          <Navbar />
          <AnimatedRoutes />
        </Router>
      </C>
    </main>
  );
};

const AnimatedRoutes: React.FC = () => {
  const location = useLocation();
  const [, setContent] = useState<boolean>();

  return (
    <>
      <TransitionGroup component={null}>
        <CSSTransition key={location.pathname} classNames="route-animation" timeout={1000}>
          <Routes location={location}>
            <Route element={<Wrapper />}>
              <Route path={routes.HOME} element={<Home />} />
              {/*
                <Route path={routes.PHYSIO} element={<Physio />} />
                <Route path={routes.MASSAGE} element={<Massage />} />
                <Route path={routes.CHIRO} element={<Chiro />} />
                <Route path={routes.ACUPUNCTURE} element={<Acupuncture />} />
                <Route path={routes.STRETCH_THERAPY} element={<StretchTherapy />} />
              */}

              <Route path={routes.ABOUT_US} element={<AboutUs />} />
              <Route path={routes.TERMS_OF_SERVICE} element={<TermsOfService />} />
              <Route path={routes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route path={routes.SERVICE} element={<Service />} />
              <Route path="*" element={<Navigate to={routes.HOME} replace />} />
            </Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      {!isTrackingAllowed() && (
        <div className={classes.cookieConsentContainer}>
          <span>
            We use cookies to improve your experience.{' '}
            <Link to={routes.PRIVACY_POLICY}>Read more about it here</Link>. By using our site, you
            agree to our use of cookies.
          </span>
          <Button
            color="black"
            size="small"
            onClick={() => {
              setContent(true);
              allowTracking();
            }}
          >
            Accept
          </Button>
        </div>
      )}
    </>
  );
};

const Wrapper: React.FC = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }, [location.pathname]);
  return <Outlet />;
};
