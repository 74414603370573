const routes: { [r: string]: string } = {
  HOME: '/',
  ABOUT_US: '/about-us',
  CONTACT_US: '/contact-us',
  TERMS_OF_SERVICE: '/terms-of-service',
  PRIVACY_POLICY: '/privacy-policy',
  SERVICE: '/:slug',
};

export default routes;
