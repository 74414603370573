const COOKIE_STORAGE_KEY = 'elit-care-cookie-consent/tracking-consent-approval';

export function isTrackingAllowed() {
  return localStorage.getItem(COOKIE_STORAGE_KEY) === 'yes';
}

export function allowTracking() {
  localStorage.setItem(COOKIE_STORAGE_KEY, 'yes');
}

export function refuseTracking() {
  localStorage.setItem(COOKIE_STORAGE_KEY, 'no');
}

export function reset() {
  localStorage.removeItem(COOKIE_STORAGE_KEY);
}
