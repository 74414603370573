import { ReactComponent as CallIcon } from 'assets/icons/call.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import cn from 'classnames';
import { Button } from 'components';
import React from 'react';
import { useContentful } from 'react-contentful';
import classes from './styles.module.scss';

export type ContactBlockPropType = {
  className?: string;
};

const ContactBlock: React.FC<ContactBlockPropType> = ({ className }) => {
  const { data } = useContentful({
    contentType: 'contactInformation',
  });
  /*eslint-disable */
  const contactInfo = (data as any)?.items?.[0] || {};
  const tel = (contactInfo?.fields?.phone || '').split(' ').join('');
  const googleMapLink = contactInfo?.fields?.googleMapsLink || '';
  const addressLine = contactInfo?.fields?.address || '';
  const phoneNum = contactInfo?.fields?.phone || '';
  const email = contactInfo?.fields?.email || '';
  const scheduleLine = contactInfo?.fields?.singleScheduleLine || '';
  const janeAppLink = contactInfo?.fields?.janeAppMainUrl || '';
  return (
    <div id="contact-us-info-block" className={cn(classes.contact, className)}>
      <div className={classes.inner}>
        {/* <div className={classes.column}>
          <h3>Hours</h3>
          <div className={classes.hoursGroup}>
            <ul>
              {data.contact.hours?.map((entry) => (
                <li key={entry}>{entry}</li>
              ))}
            </ul>
            <span className={classes.hourDisclaimer}>
              Full operating hours not in effect until March 1st
            </span>
          </div>
        </div> */}
        <div className={classes.column}>
          <h3>Contact</h3>
          <div className={classes.contactGroup}>
            <a href={googleMapLink} target="_blank" rel="noreferrer">
              <div className={classes.contact}>
                <LocationIcon />
                <p>{addressLine}</p>
              </div>
            </a>
            {phoneNum && (
              <a href={`tel:${tel}`}>
                <div className={classes.contact}>
                  <CallIcon />
                  <p>{phoneNum}</p>
                </div>
              </a>
            )}
            <a href={`mailto:${email}`}>
              <div className={classes.contact}>
                <EmailIcon />
                <p>{email}</p>
              </div>
            </a>

            <a href={googleMapLink} target="_blank" rel="noreferrer">
              <div className={classes.contact}>
                <ClockIcon />
                <p>{scheduleLine}</p>
              </div>
            </a>

            <div className={classes.buttonContainer}>
              <Button onClick={() => window.open(janeAppLink, '_newtab')}>Book Now</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBlock;
